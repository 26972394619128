import React from 'react'
import Group from '../image/Group2.svg'

const Introduction = () => {
  return (
    <div>
        <div className="intro-main-sec" >
            <div className="container">
                <div className='intro-text'>
                    <h3><b>INTRODUCTION</b></h3>
                </div>
                <div className="row intro-para-text">
                    <div className="col-lg-6">
                        <p>Welcome to our website dedicated to students' knowledge empowerment! We firmly believe that knowledge is power, and every student has the potential to achieve great things through education. Our website is committed to assisting school and college students in learning! We believe that education should be accessible to all students, and learning and earning should go hand in hand. That is why we have created a unique platform that enables students to learn and take exams to earn money.</p>
                        <p>Our platform provides a variety of online courses and training programs that cover a wide range of subjects, from mathematics and science to business and marketing. Each course includes a series of lessons, quizzes, and exams designed to help students learn and reinforce their understanding of the subject matter.</p>
                    </div>
                    <div className="col-lg-6">
                        <p>But what makes our platform truly unique is that each exam comes with a monetary reward. Students can take exams to demonstrate their mastery of the subject and earn money for each exam they pass. This creates a powerful incentive for students to not cly learn but also to retain and apply what they've learned in real-world situations.</p>
                        <p>Our platform is designed to be flexible and accessible to all students. Whether you're a high school student looking to brush up on your math skills, or a college student looking to develop your business acumen, our platform has something for you. And with our easy-to-use interface and comprehensive support resources, you'll be able to learn and earn with ease.</p>
                    </div>
                </div>
                <div className='text-end'>
                    <img src={Group}  className="img-fluid" />
                </div>
            </div>
        </div>
    </div>
  )
}

export default Introduction