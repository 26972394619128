import React from 'react'

const Cmscontent = () => {
  return (
    <div>
        <div className="performance-section pt-0">
  <div className="container">
    <div className="row">
      <div className="col-12 content">
        <h1>
          <b><i><span>CMS</span> CONTENT <br /> MANAGEMENT</i></b>
        </h1>
        <p>
        Welcome to our CMS business! We empower businesses with user-friendly and customizable CMS(content management system) solutions to create, edit and publish digital content with ease. From simple blogs to complex e-commerce websites, we tailor our soldtions to meet your unique needs and goals.
<br/> We also offer additional services such as website design and development, SEO optimization, content creation, and technical support to help businesses succeed online. <br/>
Our experienced team is committed to promidling the highest level of service and staying up-to-date with the latest trends and technologies in the industry. Contact us today to learn how we can help your business thrive online with our CMS solutions.
        </p>
      </div>
    </div>
  </div>
</div>

    </div>
  )
}

export default Cmscontent