import React from 'react'
import Saira from '../image/saira.png'
import Divya from '../image/divya.png'
import Kumar from '../image/kumar.png'
import Gopal from '../image/gopal.png'
import David from '../image/david.png'

const Acknowledge = () => {
  return (
    <div>
        <div className="performance-section pt-0">
  <div className="container">
    <div className="performance mb-3">
      <h1>ACKNOWLEDGMENTS</h1>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt,
        dignissimos! Quaerat, repellat maxime ut rem natus voluptatum aliquid,
        iure nulla ipsum ipsam harum magnam? Odit labore obcaecati quod
        recusandae perspiciatis!Lorem ipsum dolor sit amet consectetur
        adipisicing elit. Incidunt, dignissimos! Quaerat, repellat maxime ut rem
        natus voluptatum aliquid, iure nulla ipsum ipsam harum magnam? Odit
        labore obcaecati quod recusandae perspiciatis!
      </p>
    </div>
    <div className="row justify-content-between mt-5">
      <div className="col-lg-2 col-md-4 col-sm-6 text-center mb-3">
        <div className="conclusion">
          <img src={Saira} className="img-fluid" alt="" />
        </div>
        <div className="knowledg">
          <h3>SAIRA KOHLI</h3>
          <p>CONSULTANT</p>
        </div>
      </div>
      <div className="col-lg-2 col-md-4 col-sm-6 text-center mb-3">
        <div className="conclusion">
          <img src={Divya} className="img-fluid" alt="" />
        </div>
        <div className="knowledg">
          <h3>DIVYA</h3>
          <p>CONSULTANT</p>
        </div>
      </div>
      <div className="col-lg-2 col-md-4 col-sm-6 text-center mb-3">
        <div className="conclusion">
          <img src={Kumar} className="img-fluid" alt="" />
        </div>
        <div className="knowledg">
          <h3>V KUMAR</h3>
          <p>ADMINISTRATION</p>
        </div>
      </div>
      <div className="col-lg-2 col-md-4 col-sm-6 text-center mb-3">
        <div className="conclusion">
          <img src={Gopal} className="img-fluid" alt="" />
        </div>
        <div className="knowledg">
          <h3>V GOPAL</h3>
          <p>IT OFFICER</p>
        </div>
      </div>
      <div className="col-lg-2 col-md-4 col-sm-6 text-center mb-3">
        <div className="conclusion">
          <img src={David} className="img-fluid" alt="" />
        </div>
        <div className="knowledg">
          <h3>CARROLL DAVID</h3>
          <p>MARKETING <br/> and planning</p>
        </div>
      </div>
    </div>
  </div>
</div>

    </div>
  )
}

export default Acknowledge