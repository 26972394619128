import React from 'react'
import Microsoft from '../image/MicrosoftLogo.png'

const Footer = () => {
  return (
    <div>
        <div className="performance-section foot-section">
  <div className="container">
    <div className="row justify-content-between align-items-center">
      <div className="col-lg-6 col-md-12 performance mb-3 row align-items-center">
        <div className="col-lg-6 col-md-6 performance">
          <h1>CONTACT</h1>
          <h3>hello@csection.co.in</h3>
         <h3>hello@csection.co.in</h3>
          
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="foot-logo">
            <img src={Microsoft} style={{width:"250px"}}  alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    </div>
  )
}

export default Footer