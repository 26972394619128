import React from 'react'

const Performance = () => {
  return (
    <div>
        <>
  {/* Hello world */}
  <div className="performance-section">
    <div className="container">
      <div className="row">
        <div className="col-lg-7 performance">
          <h1>
            PERFORMANCE <br /> INDICATORS
          </h1>
          <ul>
            <li>
              <p>Overview of the website's performance metrics</p>
            </li>
            <li>
              <p>Testimonials from satisfied customers/students</p>
            </li>
            <li>
              <p>High course completion rates</p>
            </li>
            <li>
              <p>Positive user engagement metrics</p>
            </li>
            <li>
              <p>Customer satisfaction survey results</p>
            </li>
            <li>
              <p>Awards and recognition</p>
            </li>
            <li>
              <p>Future plans for website/course development</p>
            </li>
          </ul>
          <p>
           This Performance page showcases our website's accomplishments and demonstrates our commitment to delivering quality educational services. We offer engaging courses, supportive instructors, and effective learning strategies to help our students achieve their goals. We're constantly evolving to meet the needs of our customers and provide value in their educational journey.
          </p>
        </div>
        <div className="col-lg-5 performance pt-5" style={{overflowX:"scroll"}}>
          <table className="table">
            <thead>
              <tr className="bg-yell">
                <th scope="col">KEY INDICATOR</th>
                <th scope="col">BSEB</th>
                <th scope="col">CBSE</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Number of registered users</td>
                <td>over 1.Lakh</td>
                <td>
                  <ul>
                    <li>over 1.5 lakh</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>Number of courses and training programs offered</td>
                <td>all subiects</td>
                <td>
                  <ul>
                    <li>all subiects</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>User satistaction rate</td>
                <td>Pass rate of exams</td>
                <td>
                  <ul>
                    <li>Average lucrative earnings per user</li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</>

    </div>
  )
}

export default Performance