import React from 'react'
import Conclu from '../image/coclusionimg.png'

const Conclusion = () => {
  return (
    <div>
        <div className="performance-section">
  <div className="container">
    <div className="row justify-content-between align-items-center">
      <div className="col-lg-6 col-md-6 performance">
        <h1>CONCLUSION</h1>
        <p>
        In conclusion, our "Learn and Earn" website is designed to provide a platform for pupil to gain new skills and knowledge while also earning money. We believe that learning should be a lifelong pursuit, and our website offers a diverse range of courses and resources to help you achieve your personal and professional goals Our team is dedicated to providing high-quality content and a user-friendly experience, so you can focus on learning and earning. We hope that our website inspires you to take the first step towards a brighter future, and we look forward to being a part of your learning journey.
        </p>
      </div>
      <div className="col-lg-5 col-md-6 performance conclusion">
        <img src={Conclu} className="img-fluid" alt="" />
      </div>
    </div>
  </div>
</div>

    </div>
  )
}

export default Conclusion