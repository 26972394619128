import logo from './logo.svg';
import './App.css';
import Making from './Component/Making';
import Ourservice from './Component/Ourservice';
import Introduction from './Component/Introduction';
import Meseg from './Component/Meseg';
import Sustainble from './Component/Sustainble';
import Performance from './Component/Performance';
import Ournext from './Component/Ournext';
import Conclusion from './Component/Conclusion';
import Cmscontent from './Component/Cmscontent';
import Publicrelation from './Component/Publicrelation';
import Acknowledge from './Component/Acknowledge';
import Footer from './Component/Footer';

function App() {
  return (
    <div className="App">
      <Making/>
      <Ourservice/>
      <Introduction/>
      <Meseg/>
      <Sustainble/>
      <Performance/>
      <Ournext/>
      <Conclusion/>
      <Cmscontent/>
      <Publicrelation/>
      <Acknowledge/>
      <Footer/>
    </div>
  );
}

export default App;
