import React from 'react'

const Ournext = () => {
  return (
    <div>
        <div className="our-step">
  <div className="container">
    <div className="row">
      <div className="col-lg-8 our">
        <h1>OUR NEXT STEPS</h1>
        <p>
        Focused on allowing school and college students to learn and earn through exams. and make a greater impact on students' education and economic opportunities.
        </p>
      </div>
    </div>
    <div className="row">
      <div className="mb-3 col-lg-4 col-md-6">
        <div className="expand">
          <h4 className="mb-0 p-2"><b><i>EXPAND
COUPSE
GEFERIDGS</i> &
IMPROVE COURSE
MATERIALS</b>
           
          </h4>
        </div>
      </div>
      <div className="mb-3 col-lg-4 col-md-6">
        <div className="expand">
          <h4 className="mb-0 p-2"><b><i>REFINE ASSESSMENT
METHODS</i> & PROVIDE
PERSONALIZED
FEEDBACK</b>
           
          </h4>
        </div>
      </div>
      <div className="mb-3 col-lg-4 col-md-6">
        <div className="expand">
          <h4 className="mb-0 p-2"><b>
          <i>EXPAND PAYMENT
OPTIONS </i> & ESTABLISH
PARTNERSHIPS
</b></h4>
        </div>
      </div>
    </div>
  </div>
</div>

    </div>
  )
}

export default Ournext