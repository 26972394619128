import React from 'react'
import Publicrel from '../image/publicimg.png'
import Socialim from '../image/socialimg.png'
import Creativeim from '../image/creativeimg.png'

const Publicrelation = () => {
  return (
    <div>
        <>
  <div className="performance-section pt-0">
    <div className="container">
      <div className="col-lg-6 col-md-6 performance">
        <h1>
          PUBLIC <br /> RELATIONS
        </h1>
      </div>
      <div className="row justify-content-between align-items-center">
        <div className="col-lg-6 col-md-6 performance mb-3">
          <p>
          Our PR team is responsible for managing all communications and interactions with the media, government agencies, investors, customers, and the public at large. They work closely with marketing and branding teams to ensure that messaging is consistent and aligned with overall business objectives.
Our PR team is skilled in a range sf activities, including media relations, crisis management, reputation management,
stakeholder engagement, and event management. They are experts in developing strategic communication plans that help achieve business goals and objectives.
          </p>
        </div>
        <div className="col-lg-5 col-md-6 performance mb-3">
          <img src={Publicrel} className="img-fluid" alt="" />
        </div>
      </div>
    </div>
  </div>
  <div className="our-step">
    <div className="container">
      <div className="col-lg-6 col-md-6 performance">
        <h1>SOCIAL MEDIA</h1>
      </div>
      <div className="row justify-content-between align-items-center">
        <div className="col-lg-6 col-md-6 performance mb-3">
          <p>
          social media is a powerful tool for connecting with our customers and building strong relationships with our community. Our social media team is dedicated to creating engaging content that resonates with our audience and reflects our brand values sharing information about our products and services, promotions and events, and engaging with our customers in real-time. We are active on a variety of social media platforms, including Facebook, Twitter, Instagram, LinkedIn, and more not only to promote our brand but also to educate and inform our audience.
          </p>
        </div>
        <div className="col-lg-5 col-md-6 performance mb-3">
          <img src={Socialim} className="img-fluid" alt="" />
        </div>
      </div>
    </div>
  </div>
  <div className="performance-section">
    <div className="container">
      <div className="col-lg-6 col-md-6 performance">
        <h1>
          CREATIVE <br /> STRATEGY
        </h1>
      </div>
      <div className="row justify-content-between align-items-center">
        <div className="col-lg-6 col-md-6 performance mb-3">
          <p>
          At [C Section], we believe in the power of a well-developed creative strategy for successful marketing campaigns. We consider audience understanding, clear objectives, unique selling propositions, channel selection, creative concepts, and flawless execution. Our experienced team of designers, writers, and creatives work together to develop engaging and impactful concepts that drive results. Let us help take your marketing to the next level with a custom creative strategy.
          </p>
        </div>
        <div className="col-lg-5 col-md-6 performance mb-3">
          <img src={Creativeim} className="img-fluid" alt="" />
        </div>
      </div>
    </div>
  </div>
</>

    </div>
  )
}

export default Publicrelation