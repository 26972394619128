import React from 'react'
import Microsoft from '../image/MicrosoftLogo.png'
import mesegback from '../image/meseg-background.png'

const Meseg = () => {
  return (
    <div className='meseg-main'>
        <div className="container  ">
            <div className='row meseg-text'>
                <div className="col-lg-4 col-md-4 col-sm-3">
                    <h3><b>A MESSEAGE FROM OUR LEADER</b> </h3>
                </div>
            </div>
            <div className="row meseg-para">
                <div className="col-lg-8 col-md-8 pt-5">
                    <p>Dear students,</p>
                    <p>Welcome to our platform where we believe in making education accessible, rewarding, and fun.
                    Our courses and programs are designed to help you achieve your goals and develop practical skills that will serve you well in the real world.</p>
                    <p>What sets us apart is our unique approach to incentivizing your learning. By rewarding you for passing exams and applying your knowledge in practical ways, we empoweryou to succeed in your future academic and career pursuits.</p>
                    <p>We're committed to supporting all learners, regardless of background or experience level. With our flexible learning options and robust resources, we're here to help you learn and grow at your own pace.</p>
                    <p>Thank you for choosing our platform, and we look forward to helping you achieve your goals and reach new heights!</p>
                    <p>Sincerely,</p>
                </div>
                <div className='col-lg-4 col-md-4'>
                    <div>
                        <img src={Microsoft} style={{width:"300px"}} className="img-fluid" />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-3 pt-5">
                    <h4 className='chief-text'><b>CHIEF <br></br>EXECUTIUE OFFICER</b></h4>
                </div>
            </div>

        </div>
    </div>
  )
}

export default Meseg