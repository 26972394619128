import React from 'react'
import Green from '../image/Vector1.png'
import Sky from '../image/Vector2.png'
import Yellow from '../image/Vector3.png'

const Sustainble = () => {
  return (
    <div className='sustain-main'>
        <div className="container">
            <div className='row meseg-text mb-4'>
                <div className="col-lg-8 col-md-4 col-sm-3">
                    <h3><b>SUSTAINABILITY DEVELOPEMNET GOALS</b> </h3>
                </div>
                <div className="col-lg-10 col-md-8 sustain-para">
                    <p>By promoting education and economic opportunity, a website focused on allowing school and college students to learn and earn through exams can contribute to multiple Sustainable Development Goals and help create a more sustainable and equitable future.By working together, we can help ensure that more students have access to the education and economic opportunities they need to succeed.</p>
                </div>
            </div>

            <div>
                <div className="row justify-content-center">
                    <div className="col-lg-4 col-md-6 my-2">
                        <div className='quali-img pt-3'>
                            <img src={Green} className="img-fluid" />
                        </div>
                        <div className="quali-text">
                            <h4><b>QUALITY EDUCTION</b></h4>
                            <p className='pt-3'>By providing online courses and training programs, the website can help improve access to quality education for students who may not have access to traditional educational opportunities. Additionally, by incentivizing students to learn and apply their knowledge throug exams, the platform can encourage students to take their education seriously and develop valuable skills for their future careers.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 my-2">
                        <div className='quali-img pt-3'>
                            <img src={Sky} className="img-fluid" />
                        </div>
                        <div className="quali-text">
                            <h4><b>ECONOMIC GROWTH</b></h4>
                            <p className='pt-3'>By offering a platform that allows students to earn money through their academic pursuits, the website can contribute to the goal of promoting decent work and economic growth. This can be especially important for students who may need to earn money while studying, or who may be facing financia challenges.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 my-2">
                        <div className='quali-img pt-3'>
                            <img src={Yellow} className="img-fluid" />
                        </div>
                        <div className="quali-text">
                            <h4><b>REDUCE INEQUALITIES</b></h4>
                            <p className='pt-3'>By offering flexible pavment options and scholarships to ensure that the platform is accessible to students from all backgrounds. the websites can contribute the goal oF reducing inequalities. This can help ensure that all students have an equal opportunio to learn and earn through the platform regardless of their socioeconOmIc Status.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Sustainble