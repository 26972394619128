import React from 'react'

const Ourservice = () => {
  return (
    <div className='our-main-sec'>
        <div className="container">
            <div className='our-servi'>
                <h2>OUR SERVICES</h2>
            </div>
            <div className="row our-service-text justify-content-center">
                <div className="col-lg-3 col-md-5 col-sm-8  mb-3">
                    <p>ONLINE TUTORING</p>
                    </div>
                <div className="col-lg-3 col-md-5 col-sm-8  mb-3">
                    <p>CMS</p>
                    </div>
                <div className="col-lg-3 col-md-5 col-sm-8  mb-3">
                    <p>CREATIVE STETEGY</p>
                    </div>
                <div className="col-lg-3 col-md-5 col-sm-8  mb-3">
                    <p>PUBLIC RELAYION</p>
                    </div>
                <div className="col-lg-3 col-md-5 col-sm-8  mb-3">
                    <p>SOCIAL MEDIA MANAGEMNET</p>
                    </div>
                <div className="col-lg-3 col-md-5 col-sm-8  mb-3">
                    <p>CONTACT</p>
                    </div>
            </div>
        </div>
    </div>
  )
}

export default Ourservice