import React from 'react'
import Microsoft from '../image/MicrosoftLogo.png'

const Making = () => {
  return (
    <div className='making-sec'>
        <div className="container">
            <div className="row justify-content-between">
                <div className="col-lg-4 col-md-3 col-sm-2 pt-3">
                    <img src={Microsoft} style={{width:"250px"}} className="img-fluid" />
                </div>
                <div className="col-lg-8 col-md-7 col-sm-7">
                    <div className='log-button pt-3'>
                        <button>Log In</button>
                        <button>Sign Up</button>
                    </div>
                    <div className='making-text'>
                        <p><b>MAKING CONNECTION DELIVERING RESULTS</b></p>
                        <a href="">BUILDING YOUR SOCIAL BRAND</a>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
  )
}

export default Making